<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="primary" v-on="on" v-bind="attrs" icon class="white--text">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card elevation="0">
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier le prix</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="my-2">
        <v-select v-model="referentielModel.category" :items="categories" item-value="id" item-text="name" label="Catégorie"></v-select>
        <v-text-field label="Code" v-model="referentielModel.code"></v-text-field>
        <v-text-field label="Description" v-model="referentielModel.description"></v-text-field>
        <v-text-field label="Unité" v-model="referentielModel.unit"></v-text-field>
        <v-text-field label="Prix" v-model="referentielModel.price"></v-text-field>
        <v-checkbox :disabled="logged_user.group === 'Instructeur' || logged_user.group === 'Administrateur'" label="Validé" v-model="referentielModel.validated"></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="editing" @click="editPrice" color="primary">Modifier</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="editing"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import {AuthMixin} from "@/mixins/AuthMixin";

export default {
  name: 'EditReferentiel',
  props: {
    price: Object,
    categories: Array,
  },
  mixins: [AuthMixin],
  data() {
    return {
      referentielModel: {
        id: this.price.id,
        category: this.price.category.id,
        code: this.price.code,
        description: this.price.description,
        unit: this.price.unit,
        price: this.price.price,
        validated: this.price.validated,
      },
      dialog: false,
      editing: false,
    }
  },
  methods: {
    async editPrice() {
      this.editing = true;
      let result = await this.$store.dispatch("referentiel/patchReferentiel", this.referentielModel);
      if (result === null) {
        await this.$store.dispatch("annonce/annonceSuccess", "Prix modifié.");
      }
      this.editing =false;
      this.dialog = false;
    }
  }
}
</script>